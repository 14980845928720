import {
  BaseStepIdEnum,
  FieldEntityTypeEnum,
  FieldPropertyTypeEnum,
  FieldTypeEnum,
  StepConfig,
  StepTypeEnum,
} from 'shared-domain';

export const stepsConfig: StepConfig[] = [
  {
    id: 'introduction',
    type: StepTypeEnum.text,
  },
  {
    id: BaseStepIdEnum.company_search,
  },
  {
    id: BaseStepIdEnum.company_list,
  },
  {
    id: BaseStepIdEnum.company_edit,
  },
  {
    id: 'address_details',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'street_address',
          type: FieldTypeEnum.text,
          nested: 'address',
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'street_address_2',
          type: FieldTypeEnum.text,
          nested: 'address',
          isEnabled: true,
          isRequired: false,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'city',
          type: FieldTypeEnum.text,
          nested: 'address',
          isEnabled: true,
          isRequired: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'postal_code',
          type: FieldTypeEnum.text,
          nested: 'address',
          isEnabled: true,
          isRequired: false,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'country',
          type: FieldTypeEnum.country,
          nested: 'address',
          isEnabled: true,
          isRequired: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'po_box_and_mailing_address',
          type: FieldTypeEnum.text,
          isEnabled: true,
          isRequired: false,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
  {
    id: 'business_legality',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'trade_license_no',
          type: FieldTypeEnum.text,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'trade_license_date_of_issue',
          type: FieldTypeEnum.date,
          isEnabled: true,
          isRequired: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'trade_license_date_of_expiry',
          type: FieldTypeEnum.date,
          isEnabled: true,
          isRequired: false,
          hasHelper: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'tax_identification_number',
          type: FieldTypeEnum.text,
          isEnabled: true,
          isRequired: false,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
      ],
    },
  },
  {
    id: 'financial_information',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'designated_bank_account',
          type: FieldTypeEnum.text,
          isEnabled: true,
          isRequired: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'bank_branch',
          type: FieldTypeEnum.text,
          isEnabled: true,
          isRequired: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'bank_address',
          type: FieldTypeEnum.text,
          isEnabled: true,
          isRequired: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'bank_account_no',
          type: FieldTypeEnum.text,
          isEnabled: true,
          isRequired: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'currency',
          type: FieldTypeEnum.text,
          isEnabled: true,
          isRequired: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'iban',
          nested: 'banking_information',
          type: FieldTypeEnum.iban,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'bic',
          nested: 'banking_information',
          type: FieldTypeEnum.bic,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
      ],
    },
  },
  {
    id: BaseStepIdEnum.individuals_list,
    config: { hasApplicant: true },
  },
  // Enable the individual_edit step only for a KYC
  /*{
    id:  BaseStepIdEnum.individual_edit,
  },*/
  {
    id: 'applicants_phone_number',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'applicants_phone_number',
          type: FieldTypeEnum.tel,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
  {
    id: 'client_declaration',
    type: StepTypeEnum.terms_and_conditions,
    config: {
      pdfUrl: 'https://cta-light.onboarding.dotfile.com/assets/Declaration.pdf',
    },
  },
  {
    id: 'conclusion',
    type: StepTypeEnum.text,
  },
];
